@import "~bootstrap/dist/css/bootstrap.css";

/* @import "~font-awesome/css/font-awesome.css"; */

html,
 body {
    height: 100%;
    /* overflow: hidden !important ; */
}

body {
    background: #fff;
    font-family: "Lato", "Calibri", sans-serif !important;
    font-size: 14px;
    color: #333;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 1.5;
    margin: 0;
    user-select: text;
    height: 100%;
    overflow: none !important;
}

.tooltip-black {
    background-color: #000000 !important;
    color: #fff;
}

select {
    cursor: pointer;
}

.WH-sectionContent {
    min-height: calc(100vh - 122px);
}

.WH-employeePage {
    min-height: calc(100vh - 122px);
}

.WH-sidebar {
  min-height: 210px !important;
    min-height: calc(100vh - 122px);
}

.WH-sidebar-bg {
    min-height: calc(100vh - 122px);
}

@media screen and (max-width: 767px) {
    .WH-sectionContent {
        min-height: auto;
    }
    .WH-employeePage {
        min-height: auto;
    }
}

@media screen and (max-width: 1023px) {
    .WH-sidebar {
        min-height: auto;
    }
    .WH-sidebar-bg {
        min-height: auto;
    }
}

ngb-pagination ::ng-deep .page-item.active .page-link {
    border-color: #e30613;
    background-color: #e30613;
    box-shadow: none !important;
}

:root {
    --wahr-background: #e30613 !important;
    --wahr-color: #e30613 !important;
    --wahr-button-color: #e30613 !important;
    --sidemenu-colors: #e30613 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ffff0000 !important;
}

.vertical {
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: inherit;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

ul {
    padding: 0;
    margin: 0;
}

ul li {
    list-style: none;
    list-style-image: none;
    list-style-type: none;
}

input,
textarea {
    font-family: inherit;
    outline: none;
}

.truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.space-5 {
    height: 15px;
}

.space-10 {
    height: 30px;
}

.bg-white {
    background-color: #fff;
}

.w-100 {
    width: 100% !important;
}

.heightManu.mat-dialog-container {
    height: auto;
}

.mt-30 {
    margin-top: 30px !important;
}

.b-0 {
    border: 0 !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.pb-15 {
    padding-bottom: 15px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.pt-40 {
    padding-top: 40px;
}

.text-right {
    text-align: right;
}
.pb-8{
    padding-bottom: 8px !important;
}


/**** Form *******/

.form-control {
    padding: 0px 10px 3px 5px !important;
    border: 1px solid #ebebeb;
    /* border: 1px solid #ddd; */
    height: 30px !important;
}

.form-group {
    margin-bottom: 14px !important;
}

.form-group label {
    font-size: 14px;
    color: #222;
    margin-bottom: 3px;
}

.V-hidden {
    visibility: hidden;
}

.py-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding: 0 !important;
}

.row {
    margin: 0 !important;
}

.form-control:focus {
    border-color: #ddd !important;
    box-shadow: none !important;
}

/* .form-control {
    border: 1px solid #ddd;
    height: 30px !important;
} */


/******** button ***********/

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:active {
    box-shadow: none !important;
    background-color: #e30613 !important;
    border-color: #ddd !important;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: none !important;
}

.btn.focus,
.btn:focus {
    box-shadow: none !important;
}


/**** Hide Input Arrow numbers ******/


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.alert-danger {
    font-size: 14px !important;
    padding: 2px 6px !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
}

.input-group {
    display: table !important;
}

.input-group-append {
    display: table-cell !important;
    height: 40px !important;
    width: 40px !important;
    text-align: center !important;
}

.input-group-text {
    padding: 0 !important;
    height: 100% !important;
}

.WH-visibiltyIcon {
    width: 100% !important;
    font-size: 25px !important;
    cursor: pointer;
}

.btn {
    font-family: Lato, sans-serif !important;
}

.mat-tab-group {
    font-family: Lato, sans-serif !important;
}

.mat-tab-label,
.mat-tab-link {
    font-family: Lato, sans-serif !important;
}

input,
select,
textarea,
button {
    font-family: Lato, sans-serif !important;
}


/************ Table ************/
.h-30{
    height: 30px;
}
.table th,
.table td {
    vertical-align: middle !important;
}

.table thead th {
    border-bottom: 0 !important;
}

.table th,
.table td {
    border-top: 0 !important;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #f4f4f4;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}


/* .table th, .table td{
    padding: 12px !important;
} */


/***** Common Css *******/

.WH-table-responsive {
    padding: 25px 15px;
    overflow-x: auto;
    white-space: nowrap;
}

.h-100vh {
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .form-group label {
        font-size: 14px;
    }
    .WH-table-responsive {
        padding: 20px 10px;
    }
}


/******* Buttons ********/

.btn {
    font-weight: 500;
    outline: none;
    border-radius: 0;
    cursor: pointer;
    padding: 0 10px;
}

.btn-default {
    background-color: #f4f4f4;
    color: #424242;
    font-size: 14px;
    height: 40px;
}

.btn-primary {
    background-color: #e30613;
    color: #fff;
    font-size: 16px;
    height: 40px;
    border: 1px solid #e30613 !important;
}

option {
    text-align: left !important;
}

.btn-primary:hover {
    background-color: #e30613;
    color: #fff;
    border: 1px solid #e30613 !important;
}

.btn-primary:focus {
    background-color: #e30613;
    color: #fff;
}

.btn-width {
    width: 140px;
}

.blue-snackbar {
    background: #2196f3;
    margin-bottom: 45vh !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    background-color: #e30613;
    border-color: #e30613 !important;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    background-color: rgb(64, 191, 64);
    border-color: rgb(64, 191, 64);
}
.btn-success {
    background-color: rgb(64, 191, 64)!important;
    color: #fff;
    font-size: 16px;
    height: 40px;
    border: 1px solid rgb(64, 191, 64)!important;
}

.btn-success:hover {
    background-color: rgb(64, 191, 64);
    color: #fff;
    border: 1px solid rgb(64, 191, 64);
}

.btn-success.focus {
    box-shadow: none;
}

.btn-success:focus {
    box-shadow: none;
}

/******** Pagination *******/


/* .WH-sectionContent{
    background-color:#f4f4fa;
    padding: 15px;
    height: auto;
}
@media screen and ( min-height: 700px){
    .WH-sectionContent{
      height: calc(100vh - 75px);
    }
}
@media screen and ( max-width: 576px){
    .WH-sectionContent{
         height: auto;
      }
} */


/* .mat-dialog-container{
    width: 50% !important;
    height: 50% !important;
    position: fixed;

 } */

.deleteModal .mat-dialog-container {
    overflow-y: hidden;
    overflow-x: hidden;
}
.mb-2{
    margin-bottom: 2px;
}

/****** Table Responsive ********/

@media screen and (max-width: 767px) {
    .WH-table-responsive {
        padding: 10px 0;
    }
    .table th,
    .table td {
        padding: 8px 10px !important;
        font-size: 13px !important;
    }
    .form-group input {
        font-size: 13px !important;
    }
    .form-group label {
        font-size: 13px !important;
    }
    .btn-primary {
        font-size: 13px !important;
    }
    .WH-btnWidth {
        width: 120px !important;
    }
    .btn-default {
        font-size: 13px !important;
    }
}


/* @media screen and (min-width: 1200px) {
    .mat-dialog-container{
        display: block !important;
        padding: 24px !important;
        border-radius: 4px !important;
        box-sizing: border-box !important;
       width: 40% !important;
        min-height: inherit;
         position: absolute;
      /* margin-top: -250px;  */


/* }
 }    */

.backButton {
    width: 200px;
    height: 40px;
    background-color: #f4f4f4;
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #424242 !important;
}


/* mat dilog box styles for all models */

.matHeadTitle {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    font-family: "Lato", "Calibri", sans-serif !important;
    color: #e30613;
}

.WH-btnWidth {
    width: 150px;
}

.matContent {
    margin-bottom: 5px;
    color: #424242;
    font-size: 14px;
    text-align: center;
    padding-bottom: 10px;
}

.WH-btns {
    display: flex;
    justify-content: space-between;
    /* margin: 20px 0 34px; */
    width: 100%;
}

.mat-dialog-container {
    height: fit-content !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 10px;
}


/* toggle */

.mat-slide-toggle-thumb {
    background-color: #fff !important;
    width: 10px !important;
    height: 10px !important;
    margin-left: -3px !important;
    box-shadow: unset !important;
}

.mat-slide-toggle.mat-disabled {
    opacity: 2 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #e30613 !important;
}
.mat-dialog-actions{
    padding: 0px !important;
    margin: 20px 0px -6px 0px !important;
}
.cdk-overlay-backdrop-loader {
    background-color: #ffffff;
    opacity: 0.5 !important;
}
/* input.ng-invalid.ng-touched {
    border: 1px solid red !important;
  } */
  input.ng-invalid.ng-dirty:not(.removeBorder) {
    border: 1px solid red !important;
  }

  .mat-dialog-container {
    max-height: 80% !important;
}

@media screen and (min-width:600px) and (max-width:850px){
    .cdk-overlay-pane {
        width: 55%!important;
    }
  }
  @media screen and (min-width:850px) and (max-width:1100px){
    .cdk-overlay-pane {
        width: 48%!important;
    }
  }
  @media screen and (min-width:1100px) and (max-width:1300px){
    .cdk-overlay-pane {
        width: 37%!important;
    }
    .cdk-global-overlay-wrapper .clientRequest{
        margin-top: 24% !important;
      }
  }
  .mat-dialog-title{
    margin-top: -5px !important;
  }
   .cdk-global-overlay-wrapper .addFdeliverySation{
    margin-top: 18% !important;
}
.cdk-global-overlay-wrapper .heightManu{
    margin-top: 5% !important;
}
.cdk-global-overlay-wrapper .StorageComponent{
    margin-top: 110px !important;
}
.cdk-global-overlay-wrapper .clientRequest{
    margin-top: 19% !important;
  }
